<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="form-group mt-1">
          <el-form>
            <label class="field-title-label"
              >Currency Name <span class="red-asterisk">*</span></label
            >
            <el-input
              type="text"
              v-model="field.label"
              placeholder="Enter currency name"
            ></el-input>
          </el-form>
        </div>
      </el-col>

      <el-col :span="24">
        <div class="form-group mt-1 mb-2">
          <el-form>
            <label class="field-title-label"
              >Currency Type <span class="red-asterisk">*</span></label
            >
            <br />
            <el-select
              v-model="field.default_value"
              clearable
              placeholder="Select Currency Type"
            >
              <el-option
                v-for="currency in CurrencyTypes"
                :key="currency.value"
                :label="`${currency.name} (${currency.value})`"
                :value="currency.value"
              ></el-option>
            </el-select>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "templates-formComponents-CurrencyTypes",
  props: ["field", "fieldsData"],
  data() {
    return {
      CurrencyTypes: [
        { value: "USD", name: "$" },
        { value: "CAD", name: "$" },
        { value: "AUD", name: "$" },
        { value: "INR", name: "₹" },
        { value: "EUR", name: "€" },
      ],
    };
  },
};
</script>

<style lang="scss">
.mgtop {
  margin-top: 10px;
}
.red-asterisk {
  color: red;
}
</style>
